import React from 'react'
import Layout from '../templates/Master'
import LineBreak from '../components/general/LineBreak'
import { Box, Grid } from '@mui/material'
import Form from '../modules/Form'
import Media from '../modules/Media'
import Content from '../modules/Content'
import Carousel from '../modules/Carousel'
import Share from '../modules/Share'
import Heading from '../modules/Heading'
import SocialMediaPost from '../modules/SocialMediaPost'
import StartCountDown from '../modules/StartCountDown'

const CardPage = () => {
    return (
        <Layout>
            <Media media = {[
                "comicconnect-mark-zuckerberg-rookie-card-front-middle.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_day_camp_1993_a9968c0984.jpg",
                "1000_1_f58fd03a50.jpg" ]} />
            <Box textAlign="center" my={8}>
                <Heading alignText="center" as='h1' content='Backstory to the Mark Zuckerberg Baseball Card' />
            </Box>
            <LineBreak short />
            <Box textAlign="center" mt={8} mb={4}>
                <Heading alignText="center" as='h3' content='Get ready!' />
            </Box>
            <Grid alignItems='center' container="true" justifyContent='center' mb={8}>
                <StartCountDown />
            </Grid>
            <LineBreak short />
            <Content
                alignText="center"
                content="<p>In 1992, an eight year old boy attended Elmwood Camp in Westchester County, New York and dreamed of making it in the big leagues! Weighing in at just 48 lbs, the young infielder notched up a remarkable .920 batting average.</p><p>After discussing his future career with his favorite counselor, Allie Tarantino, the aspiring young player was kind enough to gift Allie the one of a kind baseball card that Mark’s own mother had had made by a local printer. Half-jokingly, Allie asked the young man to sign the card. After nearly 3 decades, Allie never expected to have in his possession the world’s first and only tech giant rookie card signed by the genius mind behind Meta, Mark Zuckerberg.</p>" />
            <Box textAlign="center">
                <Heading alignText="center" as='h2' content='Unique Piece of History Consigned to ComicConnect' />
            </Box>
            <Content
                alignText="center"
                content="<p>As a leading authority on collectibles, it was inevitable that this unique piece of history came into ComicConnect's possession. Our team has worked closely with Allie, waiting for the perfect opportunity to unveil it. We could think of no better way than to launch our initiative in the world of digital collectibles than with a side-by-side auction of the physical card and <a href='/nft'>the NFT</a>.</p><h2>What’s included?</h2><p>The winner of the physical card will receive by mail:</p><p>The original certificate of authentication from University Archives signed by President, John Reznikoff.</p><p>A copy of the verification letter from the original printer and card maker, Irvin Simon, signed by President, Steve Miller.</p><p>The rookie baseball card and Mark Zuckerberg’s signature on it are verified by leading experts, including the original card maker, Steve Miller and noted signature authenticator, John Reznikoff!</p>"/>
            <LineBreak />
            <Box textAlign="center" mt={8}>
                <Heading alignText="center" as='h3' variant='h3' content='To bid on the Card, make sure you register for the Zuck auction early!' width={['auto', 'auto', 'auto', 'auto', 800]} />
            </Box>
            <Content
                alignText="center"
                content="<p>Don't miss this historic event! Please sign up here to stay up to date and receive sneak previews!</p>"
                width={['auto', 'auto', 'auto', 'auto', 800]}/>
            <Form />
            <LineBreak short/>
            <Box textAlign="center" my={8}>
                <Heading as='h3' content='What Mark Zuckerberg said on FB' />
            </Box>
            <SocialMediaPost type='facebook' />
            <LineBreak short />
            <Box textAlign="center" mt={8}>
                <Heading as='h3' content='What Mark Zuckerberg said on IG' />
            </Box>
            <Content
                alignText="center"
                content="<p>“In honor of expanding digital collectible NFTs to 100 more countries on Instagram and launching new integrations with Coinbase and Dapper, I᾿m sharing my soon-to-be NFT old little league baseball card, which someone recently found and sent to me... 😂 @mcomicconnect has the backstory on my baseball card.”</p>" />
            <SocialMediaPost />
            <LineBreak />
            <Box textAlign="center" my={8}>
                <Heading alignText="center" as='h2' content='Photos and Video of the Original 1992 Mark Zuckerberg Baseball Card & NFT' />
            </Box>
            <Carousel video media={[
                "comic-connect-1992-mark-zuckerberg-rookie-card-poster-frame-NFT.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-front-middle.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-day-camp-1992-with-card.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-day-camp-1992-bear.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_day_camp_counselor_c92b07296f.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_irvin_simon_c85982ae3a.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_day_camp_1993_a9968c0984.jpg" ]}/>
            <LineBreak />
            <LineBreak />
            <Box textAlign="center" my={8}>
                <Heading alignText="center" as='h2' width={['auto', 'auto', 'auto', 'auto', 500]} content='Tell your friends about the Mark Zuckerberg baseball card & NFT auction!' />
            </Box>
            <Box textAlign="center" mb={5}>
                <Share />
            </Box>
        </Layout>
    )
}

export default CardPage
