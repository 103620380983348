import React from 'react'
import { Box, Grid } from '@mui/material'
import styled from 'styled-components'
import Image from '../components/Images'
import Video from '../components/Videos'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled as muiStyled } from '@mui/material/styles'

const MediaContainer = styled(Box)`flex: 1;`

function Media({ type, media, videoSrcURL, videoTitle, videoThumbnail }) {
    const theme = useTheme()
    const desktopView = useMediaQuery(theme.breakpoints.up('lg'))
    const Container = muiStyled(Grid)(() => (
        {
            flexDirection: desktopView? 'row' : 'column',
            flexWrap: 'nowrap',
        }
    ))
    let content=null
    switch (type) {
    case 'video':
        content =
            <Container container="true">
                <MediaContainer>
                    <Video videoSrcURL={videoSrcURL} videoTitle={videoTitle} videoThumbnail={videoThumbnail}/>
                </MediaContainer>
            </Container>
        break
    default: // image
        content=
            <Container container="true">
                {
                    media ? media.map((path, i) => {
                        return (
                            <MediaContainer key={i}>
                                <Image src={path} />
                            </MediaContainer>
                        )
                    }): null
                }
            </Container>
    }
    return (
        <>
            {content}
        </>
    )
}

export default Media
